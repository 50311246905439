import { Modal, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import axios from 'axios';

const AddNoteModal = ({ open, onClose, onSubmit, listingId }) => {
  const [note, setNote] = useState('');

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'https://rental.seorad.online/api/user/notes/', 
        {
          listing_id: listingId,
          note: note
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          }
        }
      );
      
  
      if (response.status === 200 || response.status === 201) {
        onSubmit(note); 
        setNote('');  
        onClose();
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };
  

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
        AGGIUNGI UNA NOTA
        </Typography>
        <TextField
          fullWidth
          label="La tua nota"
          value={note}
          onChange={handleNoteChange}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" color='black' onClick={handleSubmit}>
        SALVA NOTA
        </Button>
      </Box>
    </Modal>
  );
};

export default AddNoteModal;