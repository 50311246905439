import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Drawer,
  Button,
  InputBase,
  Checkbox,
  IconButton,
  Divider,
  Pagination,
  Select,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MenuIcon from '@mui/icons-material/Menu';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddNoteModal from './NoteModal';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LogoutIcon from '@mui/icons-material/Logout';
import { formatDistanceToNow } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Gabarito, sans-serif',
  },
});

const drawerWidth = 200;

const Dashboard = () => {
  const [listings, setListings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(window.innerWidth > 600);
  const [originalListings, setOriginal] = useState([]);
  const [expanded, setExpanded] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [expandedNotes, setExpandedNotes] = useState(new Set());
  const [notes, setNotes] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState([10])
  const [loading, setLoading] = useState(true);
  const [user, setUserName] = useState('')


  const handleOpenModal = (id) => {
    setSelectedListingId(id);  
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedListingId(null); 
  };



  const postRead = async (id) => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      navigate('/login');
      return;
    }
  
    try {
      const response = await axios.post(`https://rental.seorad.online/api/listings/read/${id}/`, {}, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
  
      setListings((prevListings) =>
        prevListings.filter((listing) => listing.id !== id)
      );
      fetchListings(currentPage)
    } catch (error) {
      console.error('Erro ao marcar como lido:', error);
    }
  };
  
  const handleSubmitNote = (note) => {
    console.log(`Note added to listing ${selectedListingId}: ${note}`);

  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (value === '') {
      setListings(originalListings); 
    } else {
      const filtered = originalListings.filter((listing) => 
        listing.title.toLowerCase().includes(value)
      );
      setListings(filtered);
      setCurrentPage(1);
    }
  };
  const fetchListings = async (page) => {
    const token = localStorage.getItem('token');
    if (!token) {
        navigate('/login');
        return;
    }

    setLoading(true);

    try {
        const response = await axios.get(`https://rental.seorad.online/api/listings/?page=${page}&page_size=${itemsPerPage}&search=${searchTerm}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });

        const results = response.data.results || [];
        const filteredResults = results.filter(listing => listing.read !== true);
        setListings(filteredResults);
        setTotalCount(response.data.count);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            setError('Unauthorized access, please login again.');
            localStorage.removeItem('token');
            navigate('/login');
        } else {
            setError('Error fetching listings.');
        }
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    fetchListings(currentPage); 
  }, [currentPage, searchTerm, itemsPerPage]);

  const handleChangePage = (event, value) => {
    setCurrentPage(value); 
  };
  const toggleFavorite = async (id) => {
    const token = localStorage.getItem('token');

    if (!token) {
        navigate('/login');
        return;
    }

    try {
        const response = await axios.post(`https://rental.seorad.online/api/listings/favorite/${id}/`, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        console.log(response)
        
        setListings((prevListings) =>
            prevListings.map((listing) => {
                if (listing.id === id) {
                    return { 
                        ...listing, 
                        favorite: response.data.status === 'Favorited' 
                    };
                }
                return listing;
            })
        );
        fetchListings(currentPage)

    } catch (error) {
        setError('Error toggling favorite status.');
    }
};

const toggleContacted = async (id) => {
    const token = localStorage.getItem('token');

    if (!token) {
        navigate('/login');
        return;
    }

    try {
        const response = await axios.post(`https://rental.seorad.online/api/listings/contact/${id}/`, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        setListings((prevListings) =>
          prevListings.map((listing) => {
              if (listing.id === id) {
                  return { 
                      ...listing, 
                      contacted: response.data.status === 'Contacted' 
                  };
              }
              return listing;
          })
      );
      fetchListings(currentPage)
    } catch (error) {
        setError('Error toggling contacted status.');
    }
};

const fetchNotes = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      'https://rental.seorad.online/api/user/notes/', 
      {
        headers: {
          Authorization: `Token ${token}`,
        }
      }
    );
    
   
    setNotes(response.data.notes);

  } catch (error) {
    console.error("Erro ao adicionar a nota: ", error);
  }
};


const toggleExpand = (id) => {
  setExpanded((prev) => {
    const newExpanded = new Set(prev);
    if (newExpanded.has(id)) {
      newExpanded.delete(id);
    } else {
      newExpanded.add(id);
    }
    return newExpanded;
  });
};

const toggleExpandNotes = (id) => {
  console.log('lala')
  fetchNotes()
  setExpandedNotes((prev) => {
    const newExpanded = new Set(prev);
    if (newExpanded.has(id)) {
      newExpanded.delete(id);
    } else {
      newExpanded.add(id);
    }
    return newExpanded;
  });
};

const handleLogout = () => {
  localStorage.removeItem('token');
  navigate('/login');
};

useEffect(() => {
  fetchName();
  const handleResize = () => {
    setIsDrawerOpen(window.innerWidth > 900);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const handleItemsPerPageChange = (event) => {
  const value = event.target.value;
  setItemsPerPage(value === 'all' ? totalCount : value);
  setCurrentPage(1);

};

const fetchName = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      'https://rental.seorad.online/api/name/', 
      {
        headers: {
          Authorization: `Token ${token}`,
        }
      }
    );
    console.log(response.data.first_name)
   
    setUserName(response.data.first_name);


  } catch (error) {
    console.error("Erro ao adicionar a nota: ", error);
  }
};

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: isDrawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
          ml: `${drawerWidth}px`,
          backgroundColor: 'white',
          color: 'black',
          transition: 'margin-left 0.3s ease',

        }}
      >
        <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => setIsDrawerOpen(prev => !prev)}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f1f1f1',
            borderRadius: '4px',
            padding: '0 10px',
            width: { xs: '100%', md: '40%' }, 
            boxShadow: 1, 
          }}
        >
          <SearchIcon sx={{ color: 'gray', marginRight: '8px' }} /> 
          <InputBase
            placeholder="Search listings..."
            value={searchTerm}
            onChange={handleSearch}
            sx={{
              flex: 1,
              '& .MuiInputBase-input': {
                padding: '8px',
                border: 'none',
                '&:focus': {
                  outline: 'none',
                },
              },
            }}
          />
        </Box>
        </Toolbar>
      </AppBar>

      <ThemeProvider theme={theme}>
      <Drawer
        variant="persistent"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: 'white', textAlign: 'center', marginTop: '10px' }}
        >
          AffittoZen 🧘
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ color: 'white', textAlign: 'center', marginTop: '8px' }}
        >
          Benvenuto, {user}
        </Typography>

        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {[
              { text: 'In arrivo', icon: <MarkEmailUnreadIcon sx={{ color: 'white', marginRight: '8px' }} /> },
              { text: 'Letti', icon: <MarkEmailReadIcon sx={{ color: 'white', marginRight: '8px' }} /> },
              { text: 'Favoriti', icon: <FavoriteIcon sx={{ color: 'white', marginRight: '8px' }} /> },
              { text: 'Contattati', icon: <EmailIcon sx={{ color: 'white', marginRight: '8px' }} /> },
            ].map(({ text, icon }) => (
              <ListItem
                button
                key={text}
                onClick={() => {
                  if (text === 'In arrivo') {
                    navigate('/dashboard');
                  } else if (text === 'Letti') {
                    navigate('/read');
                  } else if (text === 'Favoriti') {
                    navigate('/favorites');
                  } else if (text === 'Contattati') {
                    navigate('/contacteds');
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#333',
                  },
                  fontFamily: 'Gabarito, sans-serif', 
                }}
              >
                {icon}
                <ListItemText
                  primary={text}
                  sx={{
                    color: 'white',
                  }}
                />
              </ListItem>
            ))}
            <ListItem
              button
              onClick={handleLogout}
              sx={{
                cursor: 'pointer',
                marginTop: 'auto',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                },
              }}
            >
              <LogoutIcon sx={{ color: 'white', marginRight: '8px' }} />
              <ListItemText primary="Logout" sx={{ color: 'white' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </ThemeProvider>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          marginLeft: { xs: 0, md: `200px` },
          marginTop: '5px',
          width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
    <Toolbar />
    {error && <Typography color="error">{error}</Typography>}

    {loading ? ( 
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress sx={{ color: 'black'}}/>
      </Box>
    ) : (
      <List>
        {listings.map((listing) => (
          <React.Fragment key={listing.id}>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          padding: '5px 0',
          minHeight: '60px',
          maxHeight: 'auto',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Tooltip title="Imposta come letto" arrow placement="top">
            <Checkbox
              checked={listing.read}
              onChange={() => postRead(listing.id)}
            />
          </Tooltip>

            <ListItemText
            primaryTypographyProps={{
              sx: {
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '400px',
              },
            }}
            secondaryTypographyProps={{
              sx: {
                fontSize: '12px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '400px',
              },
            }}
            primary={listing.title}
            secondary={`${formatDistanceToNow(new Date(listing.date_imported))} ago`}
            sx={{ maxWidth: { xs: '100%', md: '70%' }, flex: 1 }}
          />
        </Box>

        <Box
          sx={{
            display: { xs: 'none', md: 'flex' }, 
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            mt: 1,
          }}
        >
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpand(listing.id)}>
            {expanded.has(listing.id) ? 'NASCONDERE I DETTAGLI' : 'DETTAGLI'}
          </Button>
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpandNotes(listing.id)}>
            {expandedNotes.has(listing.id) ? 'NASCONDERE NOTE' : 'NOTE'}
          </Button>
          <Tooltip title="Imposta come preferito" arrow placement="top">
            <IconButton onClick={() => toggleFavorite(listing.id)}>
              {listing.favorite ? (
                <FavoriteIcon sx={{ color: 'red', fontSize: '18px' }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Imposta como contattato" arrow placement="top">
            <IconButton onClick={() => toggleContacted(listing.id)}>
              {listing.contacted ? (
                <EmailIcon sx={{ color: 'green', fontSize: '18px' }} />
              ) : (
                <EmailIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Aggiungi una nota" arrow placement="top">
            <IconButton onClick={() => handleOpenModal(listing.id)}>
              <NoteAddIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vai all'annuncio" arrow placement="top">
            <IconButton onClick={() => window.open(listing.url)}>
              <OpenInNewIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'flex-start',
          
            mt: 1,
          }}
        >
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpand(listing.id)}>
            {expanded.has(listing.id) ? 'NASCONDERE I DETTAGLI' : 'DETTAGLI'}
          </Button>
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpandNotes(listing.id)}>
            {expandedNotes.has(listing.id) ? 'NASCONDERE NOTE' : 'NOTE'}
          </Button>
          <Tooltip title="Imposta come preferito" arrow placement="top">
            <IconButton onClick={() => toggleFavorite(listing.id)}>
              {listing.favorite ? (
                <FavoriteIcon sx={{ color: 'red', fontSize: '18px' }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Imposta come contattato" arrow placement="top">
            <IconButton onClick={() => toggleContacted(listing.id)}>
              {listing.contacted ? (
                <EmailIcon sx={{ color: 'green', fontSize: '18px' }} />
              ) : (
                <EmailIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Aggiungi una nota" arrow placement="top">
            <IconButton onClick={() => handleOpenModal(listing.id)}>
              <NoteAddIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vai all'annuncio" arrow placement="top">
            <IconButton onClick={() => window.open(listing.url)}>
              <OpenInNewIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </ListItem>

            {expanded.has(listing.id) && (
              <Box mt={1}>
                <Typography variant="body2">{listing.description}</Typography>
                <Typography variant="body2">{listing.phone}</Typography>
              </Box>
            )}

            {expandedNotes.has(listing.id) && (
              <Box mt={1}>
                {notes.find((note) => note.listing_id === listing.id) ? (
                  <Typography variant="body2">
                    Note: {notes.find((note) => note.listing_id === listing.id)?.note}
                  </Typography>
                ) : (
                  <Typography variant="body2">Nessuna nota ancora</Typography>
                )}
              </Box>
            )}

            <Divider />
          </React.Fragment>
        ))}
      </List>
    )}


        <AddNoteModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitNote}
        listingId={selectedListingId}
      />

    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      
      <Pagination
        count={Math.ceil(totalCount / itemsPerPage)}
        page={currentPage}
        onChange={handleChangePage}
        variant="outlined"
        shape="rounded"
        sx={{
          marginTop: '20px',
          '& .MuiPaginationItem-root': {
            border: 'none',
            borderRadius: '4px',
            margin: '0 2px',
            backgroundColor: 'transparent',
            '&.Mui-selected': {
              backgroundColor: '#ddd',
            },
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          },
          '& .MuiButtonBase-root': {
            border: 'none',
          },
        }}
        disabled={totalCount === 0}
      />
            <Select
        value={itemsPerPage}
        onChange={handleItemsPerPageChange}
        sx={{ minWidth: '100px', minHeight:'10px' }}
      >
        <MenuItem value={5}>5 / page</MenuItem>
        <MenuItem value={10}>10 / page</MenuItem>
        <MenuItem value={20}>20 / page</MenuItem>
        <MenuItem value="all">All</MenuItem>
      </Select>
    </Box>
      </Box>
    </Box>
  );
};


export default Dashboard;
