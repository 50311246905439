import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Drawer,
  Button,
  InputBase,
  Checkbox,
  IconButton,
  Divider,
  Pagination,
  Select,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MenuIcon from '@mui/icons-material/Menu';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddNoteModal from './NoteModal';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LogoutIcon from '@mui/icons-material/Logout';
import { formatDistanceToNow } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const drawerWidth = 200;

const theme = createTheme({
  typography: {
    fontFamily: 'Gabarito, sans-serif',
  },
});


const Read = () => {
  const [read, setRead] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(window.innerWidth > 600);
  const [searchTerm, setSearchTerm] = useState('');
  const [originalFavorites, setOriginalFavorites] = useState([]);
  const [expanded, setExpanded] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [expandedNotes, setExpandedNotes] = useState(new Set());
  const [notes, setNotes] = useState([]);
  const [user, setUserName] = useState('')
  const [loading, setLoading] = useState(true);


  const handleOpenModal = (id) => {
    setSelectedListingId(id);  
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedListingId(null); 
  };

  const toggleExpandNotes = (id) => {
    fetchNotes()
    setExpandedNotes((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(id)) {
        newExpanded.delete(id);
      } else {
        newExpanded.add(id);
      }
      return newExpanded;
    });
  };

  const fetchName = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        'https://rental.seorad.online/api/name/', 
        {
          headers: {
            Authorization: `Token ${token}`,
          }
        }
      );
      console.log(response.data.first_name)
     
      setUserName(response.data.first_name);
  
  
    } catch (error) {
      console.error("Erro: ", error);
    }
  };
  

  const handleSubmitNote = (note) => {
    console.log(`Note added to listing ${selectedListingId}: ${note}`);

  };

  const fetchNotes = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        'https://rental.seorad.online/api/user/notes/', 
        {
          headers: {
            Authorization: `Token ${token}`,
          }
        }
      );
      
     
      setNotes(response.data.notes);
  
    } catch (error) {
      console.error("Erro: ", error);
    }
  };
  

  const toggleFavorite = async (id) => {
    const token = localStorage.getItem('token');

    if (!token) {
        navigate('/login');
        return;
    }

    try {
        const response = await axios.post(`https://rental.seorad.online/api/listings/favorite/${id}/`, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        console.log(response)
        
        setRead((prevListings) =>
            prevListings.map((listing) => {
                if (listing.id === id) {
                    return { 
                        ...listing, 
                        favorite: response.data.status === 'Favorited' 
                    };
                }
                return listing;
            })
        );

    } catch (error) {
        setError('Error toggling favorite status.');
    }
};

const toggleContacted = async (id) => {
    const token = localStorage.getItem('token');

    if (!token) {
        navigate('/login');
        return;
    }

    try {
        const response = await axios.post(`https://rental.seorad.online/api/listings/contact/${id}/`, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        setRead((prevListings) =>
          prevListings.map((listing) => {
              if (listing.id === id) {
                  return { 
                      ...listing, 
                      contacted: response.data.status === 'Contacted' 
                  };
              }
              return listing;
          })
      );
    } catch (error) {
        setError('Error toggling contacted status.');
    }
};


  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
  
    if (value === '') {
      setRead(originalFavorites); 
    } else {
      const filtered = originalFavorites.filter((listing) =>
        listing.title.toLowerCase().includes(value)
      );
      setRead(filtered);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };
  
  
  const fetchRead = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login');
      return;
    }
    setLoading(true);

    try {
      const response = await axios.get('https://rental.seorad.online/api/read/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      const favoriteListings = Array.isArray(response.data) ? response.data : [];
      setRead(favoriteListings);
      setOriginalFavorites(favoriteListings)
    } catch (error) {
      setError('Error fetching favorite listings.');
    }finally {
      setLoading(false);
  }
  };

  const toggleExpand = (id) => {
    setExpanded((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(id)) {
        newExpanded.delete(id);
      } else {
        newExpanded.add(id);
      }
      return newExpanded;
    });
  };

  const toggleRead = async (id) => {
    const token = localStorage.getItem('token');
    
    if (!token) {
        navigate('/login');
        return;
    }

    try {
        const response = await axios.post(`https://rental.seorad.online/api/listings/read/${id}/`, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        console.log(response);
        
        setRead((prevListings) =>
            prevListings.map((listing) => {
                if (listing.id === id) {
                    return { 
                        ...listing, 
                        read: response.data.status === 'Read' 
                    };
                }
                return listing;
            })
        );
        fetchRead(`https://rental.seorad.online/api/read/`)

    } catch (error) {
        console.error('Erro ao marcar como lido:', error);
    }
};

  useEffect(() => {
    fetchRead(); 
  }, []);

  useEffect(() => {
    fetchName()
    const handleResize = () => {
      setIsDrawerOpen(window.innerWidth > 900);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: isDrawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
          ml: `${drawerWidth}px`,
          backgroundColor: 'white',
          color: 'black',
          transition: 'margin-left 0.3s ease',

        }}
      >
        <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => setIsDrawerOpen(prev => !prev)} 
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f1f1f1',
            borderRadius: '4px',
            padding: '0 10px',
            width: { xs: '100%', md: '40%' }, 
            boxShadow: 1, 
          }}
        >
          <SearchIcon sx={{ color: 'gray', marginRight: '8px' }} /> 
          <InputBase
            placeholder="Search listings..."
            value={searchTerm}
            onChange={handleSearch}
            sx={{
              flex: 1,
              '& .MuiInputBase-input': {
                padding: '8px',
                border: 'none',
                '&:focus': {
                  outline: 'none',
                },
              },
            }}
          />
        </Box>
        </Toolbar>
      </AppBar>

      <ThemeProvider theme={theme}>
      <Drawer
        variant="persistent"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: 'white', textAlign: 'center', marginTop: '10px' }}
        >
          AffittoZen 🧘
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ color: 'white', textAlign: 'center', marginTop: '8px' }}
        >
          Benvenuto, {user}
        </Typography>

        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {[
              { text: 'In arrivo', icon: <MarkEmailUnreadIcon sx={{ color: 'white', marginRight: '8px' }} /> },
              { text: 'Letti', icon: <MarkEmailReadIcon sx={{ color: 'white', marginRight: '8px' }} /> },
              { text: 'Favoriti', icon: <FavoriteIcon sx={{ color: 'white', marginRight: '8px' }} /> },
              { text: 'Contattati', icon: <EmailIcon sx={{ color: 'white', marginRight: '8px' }} /> },
            ].map(({ text, icon }) => (
              <ListItem
                button
                key={text}
                onClick={() => {
                  if (text === 'In arrivo') {
                    navigate('/dashboard');
                  } else if (text === 'Letti') {
                    navigate('/read');
                  } else if (text === 'Favoriti') {
                    navigate('/favorites');
                  } else if (text === 'Contattati') {
                    navigate('/contacteds');
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#333',
                  },
                  fontFamily: 'Gabarito, sans-serif', 
                }}
              >
                {icon}
                <ListItemText
                  primary={text}
                  sx={{
                    color: 'white',
                  }}
                />
              </ListItem>
            ))}
            <ListItem
              button
              onClick={handleLogout}
              sx={{
                cursor: 'pointer',
                marginTop: 'auto',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                },
              }}
            >
              <LogoutIcon sx={{ color: 'white', marginRight: '8px' }} />
              <ListItemText primary="Logout" sx={{ color: 'white' }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </ThemeProvider>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          marginLeft: { xs: 0, md: `200px` },
          marginTop: '5px',
          width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {error && <Typography color="error">{error}</Typography>}
        {loading ? ( // Verificação do loading
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    ) : (
      <List>
          {read.length === 0 ? ( 
            <Typography></Typography>
          ) : (
            read.map((listing) => (
              <React.Fragment key={listing.id}>
        <ListItem
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          padding: '5px 0',
          minHeight: '60px',
          maxHeight: 'auto',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Tooltip title="Imposta come letto" arrow placement="top">
            <Checkbox
                  onChange={(e) => !e.target.checked && toggleRead(listing.id)}
                  checked={listing.read}
                />
          </Tooltip>

          <ListItemText
            primaryTypographyProps={{
              sx: {
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '400px',
              },
            }}
            secondaryTypographyProps={{
              sx: {
                fontSize: '12px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '400px',
              },
            }}
            primary={listing.title}
            secondary={`${formatDistanceToNow(new Date(listing.date_imported))} ago`}
            sx={{ maxWidth: { xs: '100%', md: '70%' }, flex: 1 }}
          />
        </Box>

        <Box
          sx={{
            display: { xs: 'none', md: 'flex' }, 
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            mt: 1,
          }}
        >
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpand(listing.id)}>
            {expanded.has(listing.id) ? 'NASCONDERE I DETTAGLI' : 'DETTAGLI'}
          </Button>
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpandNotes(listing.id)}>
            {expandedNotes.has(listing.id) ? 'NASCONDERE NOTE' : 'NOTE'}
          </Button>

          <Tooltip title="Imposta come preferito" arrow placement="top">
            <IconButton onClick={() => toggleFavorite(listing.id)}>
              {listing.favorite ? (
                <FavoriteIcon sx={{ color: 'red', fontSize: '18px' }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Imposta como contattato" arrow placement="top">
            <IconButton onClick={() => toggleContacted(listing.id)}>
              {listing.contacted ? (
                <EmailIcon sx={{ color: 'green', fontSize: '18px' }} />
              ) : (
                <EmailIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Aggiungi una nota" arrow placement="top">
            <IconButton onClick={() => handleOpenModal(listing.id)}>
              <NoteAddIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vai all'annuncio" arrow placement="top">
            <IconButton onClick={() => window.open(listing.url)}>
              <OpenInNewIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'flex-start',
          
            mt: 1,
          }}
        >
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpand(listing.id)}>
            {expanded.has(listing.id) ? 'NASCONDERE I DETTAGLI' : 'DETTAGLI'}
          </Button>
          <Button size="small" sx={{ color: 'gray' }} onClick={() => toggleExpandNotes(listing.id)}>
            {expandedNotes.has(listing.id) ? 'NASCONDERE NOTE' : 'NOTE'}
          </Button>

          <Tooltip title="Imposta come preferito" arrow placement="top">
            <IconButton onClick={() => toggleFavorite(listing.id)}>
              {listing.favorite ? (
                <FavoriteIcon sx={{ color: 'red', fontSize: '18px' }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Imposta como contattato" arrow placement="top">
            <IconButton onClick={() => toggleContacted(listing.id)}>
              {listing.contacted ? (
                <EmailIcon sx={{ color: 'green', fontSize: '18px' }} />
              ) : (
                <EmailIcon sx={{ color: 'gray', fontSize: '18px' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Aggiungi una nota" arrow placement="top">
            <IconButton onClick={() => handleOpenModal(listing.id)}>
              <NoteAddIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vai all'annuncio" arrow placement="top">
            <IconButton onClick={() => window.open(listing.url)}>
              <OpenInNewIcon sx={{ color: 'gray', fontSize: '18px' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </ListItem>

              {expanded.has(listing.id) && (
                  <Box mt={1}>
                    <Typography variant="body2">Description: {listing.description}</Typography>
                    <Typography variant="body2">Phone: {listing.phone}</Typography>
                    <Typography variant="body2">Link: <a href={listing.url || listing.link} target="_blank" rel="noopener noreferrer">{listing.url || listing.link}</a></Typography>
                    </Box>
                )}
                    {expandedNotes.has(listing.id) && (
              <Box mt={1}>
                {notes.find((note) => note.listing_id === listing.id) ? (
                  <Typography variant="body2">
                    Note: {notes.find((note) => note.listing_id === listing.id)?.note}
                  </Typography>
                ) : (
                  <Typography variant="body2">Nessuna nota ancora</Typography>
                )}
              </Box>
            )}

              <Divider />
            </React.Fragment>
            ))
          )}
             <AddNoteModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitNote}
        listingId={selectedListingId}
      />
        </List>
  
      )}
  
      </Box>
    </Box>
  );
};

export default Read;