import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Favorite from './components/Favorites';
import Contacteds from './components/Contacted';
import Read from './components/Read';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/favorites" element={<Favorite />} />
        <Route path="/contacteds" element={<Contacteds />} />
        <Route path="/read" element={<Read />} />
      </Routes>
    </Router>
  );
};

export default App;